import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取关联仓库列表
export function gitlabProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabProjects`,
    method: 'get'
  })
}// 获取gitlab仓库列表
export function allProjectList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabConfigs/${params.gitlabConfigId}/allProjectList`,
    method: 'get'
  })
}// 保存gitlabconfig
export function gitlabConfigs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabConfigs`,
    data: params.data,
    method: 'post'
  })
}// 接收code
export function sendcode(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/gitlabOauth/${params.projectId}/${params.gitlabConfigId}`,
    params: params.data,
    method: 'put'
  })
}// 校验gitlabConfig
export function validation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabConfigs/${params.gitlabConfigId}/validation`,
    method: 'get'
  })
}
// 项目下添加gitlabProject
export function savegitlabProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabProjects`,
    data: params.data,
    method: 'post'
  })
}
// 删除gitlabProject
export function deletegitlabProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gitlabConfigs/${params.gitlabConfigId}/gitlabProjects/${params.gitlabProjectId}`,
    method: 'delete'
  })
}
// 获取节点的commit活动
export function getcommits(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/gitlab/commits`,
    method: 'get'
  })
}
// 获取节点的mr活动
export function getmrs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/gitlab/mrs`,
    method: 'get'
  })
}
// Gitee发送code
export function sendcodeGitee(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/giteeOauth/${params.projectId}/${params.code}`,
    method: 'post'
  })
}
// Gitee查询代码仓库
export function giteeallProjectList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/giteeConfigs/${params.giteeConfigId}/allProjectList`,
    method: 'get'
  })
}
// Gitee选择仓库以后存入后台
export function giteeProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/giteeProjects`,
    method: 'post',
    data: params.data
  })
}
// 获取Gitee关联仓库
export function getGiteeProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/giteeProjects`,
    method: 'get'
  })
}
// 删除gitee关联仓库
export function deleteGiteeProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/giteeConfigs/${params.giteeConfigId}/giteeProjects/${params.giteeProjectId}`,
    method: 'delete'
  })
}
// 获取节点的commit活动
export function giteeCommits(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/gitee/commits`,
    method: 'get'
  })
}
// 获取节点的mr活动
export function giteeMr(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/gitee/mrs`,
    method: 'get'
  })
}
// Github发送code
export function sendcodeGithub(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/githubOauth/${params.projectId}/${params.code}`,
    method: 'post'
  })
}
// Github查询代码仓库
export function githuballProjectList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/githubConfigs/${params.githubConfigId}/allProjectList`,
    method: 'get'
  })
}
// Github选择仓库以后存入后台
export function githubProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/githubProjects`,
    method: 'post',
    data: params.data
  })
}
// 获取Github关联仓库
export function getGithubProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/githubProjects`,
    method: 'get'
  })
}
// 删除github关联仓库
export function deleteGithubProjects(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/githubConfigs/${params.githubConfigId}/githubProjects/${params.githubProjectId}`,
    method: 'delete'
  })
}
// 获取节点的commit活动
export function githubCommits(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/github/commits`,
    method: 'get'
  })
}
// 获取节点的mr活动
export function githubMr(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/github/mrs`,
    method: 'get'
  })
}
// 保存ones配置
export function saveOnesConfigs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/onesConfigs`,
    method: 'post',
    data: params.data
  })
}
// 校验onesConfig
export function getOnesConfigs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/onesConfigs/validation`,
    method: 'get'
  })
}
// 接受onesCode
export function onesOauth(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/onesOauth`,
    method: 'put',
    params: params.data
  })
}